var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-5 mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"outlined":"","dense":"","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","dense":"","persistent-hint":"","clearable":"","items":_vm.governorates,"item-text":"name","item-value":"id","label":_vm.$t('directorates.governorate')},on:{"change":_vm.refreshTable},model:{value:(_vm.governorate_id),callback:function ($$v) {_vm.governorate_id=$$v},expression:"governorate_id"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","dense":"","persistent-hint":"","clearable":"","items":_vm.directorates_filtered,"item-text":"name","item-value":"id","label":_vm.$t('directorates.directorate')},on:{"change":_vm.refreshTable},model:{value:(_vm.directorate_id),callback:function ($$v) {_vm.directorate_id=$$v},expression:"directorate_id"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","clearable":"","hide-details":"","persistent-hint":"","items":_vm.status,"item-text":"label","item-value":"value","label":_vm.$t('schoolRequest.status')},on:{"change":_vm.refreshTable},model:{value:(_vm.status_filter),callback:function ($$v) {_vm.status_filter=$$v},expression:"status_filter"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.schoolRequests,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [50, 100, 200, -1]
      },"server-items-length":_vm.total,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.refreshTable]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date_time")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [(item.status === 'accepted' || item.status === 'rejected')?_c('span',[_vm._v(_vm._s(_vm._f("format_date_time")(item.updated_at)))]):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.status === 'pending')?_c('v-chip',{attrs:{"color":"info","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('schoolRequest.pending'))+" ")]):(item.status === 'contacted')?_c('v-chip',{attrs:{"color":"warning","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('schoolRequest.contacted'))+" ")]):(item.status === 'accepted')?_c('v-chip',{attrs:{"color":"success","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('schoolRequest.accepted'))+" ")]):(item.status === 'rejected')?_c('v-chip',{attrs:{"color":"error","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('schoolRequest.rejected'))+" ")]):_vm._e()]}},{key:"item.note",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.comments && item.comments.length > 0 ? 'warning' : 'dark'},on:{"click":function($event){return _vm.viewItemNote(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-decagram ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("schoolRequest.note")))])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{staticStyle:{"flex-flow":"row"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"},on:{"click":function($event){return _vm.viewItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("schoolRequest.view")))])])],1),(item.status === 'pending' || item.status === 'contacted')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("schoolRequest.accept")))])])],1):_vm._e(),(item.status === 'pending' || item.status === 'contacted')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.transferItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-bank-transfer ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("schoolRequest.transfer")))])])],1):_vm._e(),(item.status === 'pending')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"},on:{"click":function($event){return _vm.contactedItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone-check-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("schoolRequest.contacted")))])])],1):_vm._e(),(item.status === 'pending' || item.status === 'contacted')?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.rejectItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("schoolRequest.reject")))])])],1):_vm._e()],1)]}}],null,true)})],1),_c('Dialog',{attrs:{"onClose":_vm.close,"dialog":_vm.dialog,"editedItem":_vm.editedItem,"editedIndex":_vm.editedIndex,"valid":_vm.valid,"resetValidation":_vm.resetValidation,"directorates":_vm.directorates},on:{"refreshTable":_vm.refreshTable}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogReject,"onClicked":_vm.rejectItemConfirm,"dialog-title":_vm.$t('schoolRequest.reject'),"onClose":_vm.closeReject}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogReject,"onClicked":_vm.rejectItemConfirm,"dialog-title":_vm.$t('schoolRequest.reject'),"onClose":_vm.closeReject}}),_c('view-dialog',{attrs:{"dialog":_vm.dialogView,"request":_vm.request},on:{"update:dialog":function($event){_vm.dialogView=$event}}}),_c('transfer-dialog',{attrs:{"dialog":_vm.dialogTransfer,"request":_vm.request},on:{"update:dialog":function($event){_vm.dialogTransfer=$event},"refreshTable":_vm.refreshTable}}),_c('note-dialog',{attrs:{"dialog":_vm.dialogViewNote,"request":_vm.request},on:{"update:dialog":function($event){_vm.dialogViewNote=$event},"refreshTable":_vm.refreshTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }