<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            outlined
            dense
            clearable
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            outlined
            hide-details
            @change="refreshTable"
            dense
            persistent-hint
            clearable
            v-model="governorate_id"
            :items="governorates"
            item-text="name"
            item-value="id"
            :label="$t('directorates.governorate')"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            outlined
            hide-details
            @change="refreshTable"
            dense
            persistent-hint
            clearable
            v-model="directorate_id"
            :items="directorates_filtered"
            item-text="name"
            item-value="id"
            :label="$t('directorates.directorate')"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            outlined
            dense
            clearable
            hide-details
            persistent-hint
            :items="status"
            item-text="label"
            item-value="value"
            v-model="status_filter"
            @change="refreshTable"
            :label="$t('schoolRequest.status')"
          ></v-select>
        </v-col>

      </v-row>

      <v-data-table
        :headers="headers"
        :items="schoolRequests"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [50, 100, 200, -1]
        }"
        :server-items-length="total"
        :options.sync="options"
        @update:options="refreshTable"
      >

        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | format_date_time }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <span v-if="item.status === 'accepted' || item.status === 'rejected'">{{
              item.updated_at | format_date_time
            }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status === 'pending'" color="info" small label>
            {{ $t('schoolRequest.pending') }}
          </v-chip>
          <v-chip v-else-if="item.status === 'contacted'" color="warning" small label>
            {{ $t('schoolRequest.contacted') }}
          </v-chip>
          <v-chip v-else-if="item.status === 'accepted'" color="success" small label>
            {{ $t('schoolRequest.accepted') }}
          </v-chip>
          <v-chip v-else-if="item.status === 'rejected'" color="error" small label>
            {{ $t('schoolRequest.rejected') }}
          </v-chip>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.comments && item.comments.length > 0 ? 'warning' : 'dark'"
                @click="viewItemNote(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-alert-decagram
              </v-icon>
            </template>
            <span> {{ $t("schoolRequest.note") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row style="flex-flow: row;">
            <v-col cols="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="info"
                    @click="viewItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span> {{ $t("schoolRequest.view") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" v-if="item.status === 'pending' || item.status === 'contacted'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="success"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span> {{ $t("schoolRequest.accept") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" v-if="item.status === 'pending' || item.status === 'contacted'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="transferItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-bank-transfer
                  </v-icon>
                </template>
                <span> {{ $t("schoolRequest.transfer") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" v-if="item.status === 'pending'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="warning"
                    @click="contactedItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-phone-check-outline
                  </v-icon>
                </template>
                <span> {{ $t("schoolRequest.contacted") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="2" v-if="item.status === 'pending' || item.status === 'contacted'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="error"
                    @click="rejectItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-close
                  </v-icon>
                </template>
                <span> {{ $t("schoolRequest.reject") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <Dialog
      :onClose="close"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :valid="valid"
      :resetValidation="resetValidation"
      :directorates="directorates"
      @refreshTable="refreshTable"
    />

    <confirm-dialog
      :openDialog="dialogReject"
      :onClicked="rejectItemConfirm"
      :dialog-title="$t('schoolRequest.reject')"
      :onClose="closeReject"
    />

    <confirm-dialog
      :openDialog="dialogReject"
      :onClicked="rejectItemConfirm"
      :dialog-title="$t('schoolRequest.reject')"
      :onClose="closeReject"
    />
    <view-dialog
      :dialog.sync="dialogView"
      :request="request"
    ></view-dialog>

    <transfer-dialog
      :dialog.sync="dialogTransfer"
      :request="request"
      @refreshTable="refreshTable"
    ></transfer-dialog>

    <note-dialog
      :dialog.sync="dialogViewNote"
      :request="request"
      @refreshTable="refreshTable"
    ></note-dialog>
  </v-container>
</template>
<script>
import Dialog from "../Schools/Dialog.vue";
import ViewDialog from "./Dialog.vue";
import TransferDialog from "./TransferDialog.vue";
import NoteDialog from "./NoteDialog";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import {ObjectToQuery} from "@/plugins/helper";

export default {
  components: {ConfirmDialog, Dialog, ViewDialog, TransferDialog, NoteDialog},
  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogReject: false,
      dialogView: false,
      dialogViewNote: false,
      dialogTransfer: false,
      request: null,
      status_filter: ["pending", "contacted"],
      governorate_id: null,
      directorate_id: null,
      total: 0,
      options: {},
      directorates: [],
      governorates: [],
      editedIndex: -1,
      search: "",
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      schoolRequests: [],
      editedItem: {
        id: null,
        name: null,
        free: null,
        free_cause: null,
        domain: null,
        scid: null,
        directorate_id: null,
        owner: null,
        updated_at: null,
        directorate: null,
        types: [],
        groupschs: [],
        years: [],
        locality: null,
        district: null,
        village: null,
        closest_point: null,
        manager_phone: null,
        show_in_statistics: false,
        test: false,
        student_expected_count: null,
        server_id: null,
      },
      defaultItem: {
        id: null,
        name: null,
        free: null,
        free_cause: null,
        domain: null,
        scid: null,
        directorate_id: null,
        owner: null,
        updated_at: null,
        directorate: null,
        types: [],
        groupschs: [],
        years: [],
        locality: null,
        district: null,
        village: null,
        closest_point: null,
        manager_phone: null,
        show_in_statistics: false,
        test: false,
        student_expected_count: null,
        server_id: null,
      },
      resetValidation: 0,
    };
  },
  computed: {
    directorates_filtered() {
      return this.directorates
          .filter(directorate => directorate.governorate_id === this.governorate_id || !this.governorate_id);
    },
    headers() {
      return [
        {text: this.$t("schoolRequest.school_name"), value: "school_name"},
        {text: this.$t("schoolRequest.governorate_id"), value: "governorate.name"},
        {text: this.$t("schoolRequest.directorate_id"), value: "directorate.name"},
        {text: this.$t("schoolRequest.student_count"), value: "student_count"},
        {text: this.$t("schoolRequest.manager_name"), value: "manager_name"},
        {text: this.$t("schoolRequest.status"), value: "status"},
        {text: this.$t("schoolRequest.created_at"), value: "created_at"},
        {text: this.$t("schoolRequest.updated_at"), value: "updated_at"},
        {text: this.$t("schoolRequest.note"), value: "note"},
        {text: "", value: "actions"},
      ];
    },
    status() {
      return [
        {
          value: ['pending'],
          label: this.$t('schoolRequest.pending')
        },
        {
          value: ['accepted'],
          label: this.$t('schoolRequest.accepted')
        },
        {
          value: ['contacted'],
          label: this.$t('schoolRequest.contacted')
        },
        {
          value: ['rejected'],
          label: this.$t('schoolRequest.rejected')
        },
        {
          value: ["pending", "contacted"],
          label: this.$t('schoolRequest.pending_contacted')
        },
      ];
    },
  },
  created() {
    if (!this.$store.getters.isInRole(105)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
    this.getDirectorates();
    this.GetGovernorates();
    // this.refreshTable();
  },
  watch: {
    search: {
      handler() {
        this.refreshTable();
      }
    }
  },
  methods: {
    editItem(item) {
      this.editedItem = Object.assign(this.editedItem, {
        request_id: item.id,
        name: item.school_name,
        directorate_id: item.directorate_id,
        owner: item.owner,
        types: item.types,
        locality: item.locality,
        district: item.district,
        village: item.village,
        closest_point: item.closest_point,
        manager_phone: item.manager_phone,
        manager_name: item.manager_name,
        student_expected_count: item.student_count,
      });
      this.dialog = true;
    },
    GetGovernorates() {
      this.$store
          .dispatch("GetUserGovernorates")
          .then((data) => {
            this.governorates = data;
          })
          .finally(() => {
            //   this.loading = false;
          });
    },
    getDirectorates() {
      this.$store
          .dispatch("GetUserDirectorates")
          .then((data) => {
            this.directorates = data;
            // this.directorates.push({
            //   id: null,
            //   name: this.$t("all"),
            // });
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    viewItem(item) {
      this.request = item;
      this.dialogView = true;
    },
    viewItemNote(item) {
      this.request = item;
      this.dialogViewNote = true;
    },
    transferItem(item) {
      this.request = item;
      this.dialogTransfer = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    rejectItem(item) {
      this.editedIndex = this.schoolRequests.indexOf(item);
      this.dialogReject = true;
    },
    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    rejectItemConfirm() {
      var rejectItem = this.schoolRequests[this.editedIndex];
      this.$axios
          .post(`schoolRequests/${rejectItem.id}`, {reject: true})
          .then(() => {
            this.refreshTable();
          })

      this.closeReject();
    },
    contactedItem(item) {
      this.$axios
          .post(`schoolRequests/${item.id}`, {contacted: true})
          .then(() => {
            this.refreshTable();
          });

      this.closeReject();
    },
    refreshTable() {
      this.dialog = false;
      this.dialogView = false;
      this.dialogTransfer = false;
      this.dialogReject = false;
      this.loading = true;
      let conditions = {
        ...this.options,
        search: this.search
      };
      if (this.status_filter) {
        conditions.status = this.status_filter;
      }
      if (this.directorate_id) {
        conditions.directorate_id = this.directorate_id;
      }
      if (this.governorate_id) {
        conditions.governorate_id = this.governorate_id;
      }
      const query = ObjectToQuery(conditions);
      this.$axios
          .get(`schoolRequests?${query}`, {noToast: true, failureToast: true})
          .then((response) => {
            this.schoolRequests = response.data.data.data;
            this.total = response.data.data.total;
          }).catch(() => {
        this.schoolRequests = [];
      })
          .finally(() => {
            this.$store.dispatch('GetUserAlerts', true);
            this.loading = false;
          });
    },
  },
};
</script>
