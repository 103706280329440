<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schoolRequest.view")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="request">
          <v-row>
            <v-col v-for="item in items" :key="item" cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.' + item) }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ request[item] }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.governorate_id') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ request.governorate.name }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.directorate_id') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ request.directorate.name }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.owner') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ $t('schoolRequest.' + request.owner) }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.study') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ $t('schoolRequest.' + request.study) }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.types') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <v-chip class="mx-1" v-for="item in request.types" :key="item.id" color="info" small label>
                    {{ $t('schoolTypes.' + item.school_type) }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schoolRequest.status') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <v-chip v-if="request.status === 'pending'" color="info" small label>
                    {{ $t('schoolRequest.pending') }}
                  </v-chip>
                  <v-chip v-else-if="request.status === 'accepted'" color="success" small label>
                    {{ $t('schoolRequest.accepted') }}
                  </v-chip>
                  <v-chip v-else-if="request.status === 'rejected'" color="error" small label>
                    {{ $t('schoolRequest.rejected') }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "request",
  ],
  data() {
    return {
      items: [
        'school_name',
        'manager_phone',
        'locality',
        'district',
        'village',
        'closest_point',
        'student_count',
      ],
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
};
</script>
