<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" >
      <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schoolRequest.transfer")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="request">
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="governorates"
                  item-text="name"
                  item-value="id"
                  v-model="governorate_id"
                  :rules="[rules.required]"
                  :label="$t('directorates.governorate')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="directorates_filtered"
                  item-text="name"
                  item-value="id"
                  v-model="directorate_id"
                  :rules="[rules.required]"
                  :label="$t('directorates.directorate')"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="save()" :disabled="!valid || submitLoading" :loading="submitLoading">
          {{ $t("save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "refreshTable",
    "request",
  ],
  data() {
    return {
        submitLoading: false,
        governorates: [],
        directorates: [],
        governorate_id: null,
        directorate_id: null,
        valid: false,
        rules: {
          required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        },
    };
  },
  computed: {
    directorates_filtered() {

      return this.directorates
          .filter(directorate => directorate.governorate_id === this.governorate_id);

    },
  },
  mounted() {
    this.$store
        .dispatch("GetUserGovernorates")
        .then((data) => {
          this.governorates = data;
        })
    this.$store
        .dispatch("GetUserDirectorates")
        .then((data) => {
          this.directorates = data;
        })
  },
  watch: {
    request: {
      handler() {
        this.governorate_id = null;
        this.directorate_id = null;
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    save() {
      this.submitLoading = true;
      this.$axios
          .post(`schoolRequests/${this.request.id}`, {
            governorate_id: this.governorate_id,
            directorate_id: this.directorate_id
          })
          .then(() => {
            this.$emit('refreshTable');
            this.$emit('update:dialog', false)
          })
          .finally(() => {
            this.submitLoading = false;
          })
    },
  },
};
</script>
