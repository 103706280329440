<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid" >
      <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schoolRequest.note")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="request">
          <v-row>
            <v-col cols="12" md="12">

              <v-virtual-scroll
                  :items="request.comments"
                  max-height="400"
                  item-height="100"
                  id="my-scroller"
                  :bench="3"
              >
                <template v-slot:default="{ index, item }">
                  <v-card
                      class="mx-auto"
                      outlined
                      :key="'comment-' + item.id"
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title class="mb-1">
                          <v-row>
                            <v-col cols="auto">
                              <span class="text-center">
                              {{ item.user.name }}
                              </span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                              <span class="text--secondary caption">
                                  {{ item.created_at | format_date_time }}
                              </span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn x-small icon v-bind="attrs" v-on="on" @click="removeComment(item.id, index)">
                                    <v-icon small color="error">mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("delete") }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ item.comment }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                  outlined
                  hide-details
                  persistent-hint
                  rows="1"
                  v-model="request.comment"
                  :label="$t('schoolRequest.note')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="save()" :disabled="!valid || submitLoading" :loading="submitLoading">
          {{ $t("add") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "refreshTable",
    "request",
  ],
  data() {
    return {
        submitLoading: false,
        valid: false,
        rules: {
          required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        },
    };
  },
  watch: {
    // request: {
    //   handler() {
    //     setTimeout(() =>
    //         document.getElementById("my-scroller").scrollTop = document.getElementById("my-scroller").scrollHeight, 500);
    //   }
    // },
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    save() {
      this.submitLoading = true;
      this.$axios
          .post(`comments/${this.request.id}/schoolRequests`, {
            comment: this.request.comment,
          })
          .then((response) => {
            this.$emit('refreshTable');
            this.request.comments.push(response.data.data);
            this.request.comment = '';
            setTimeout(() =>
                document.getElementById("my-scroller").scrollTop = document.getElementById("my-scroller").scrollHeight, 500);
            // this.$emit('update:dialog', false)
          })
          .finally(() => {
            this.submitLoading = false;
          })
    },
    removeComment(id, index) {
      this.submitLoading = true;
      this.$axios
          .delete(`comments/${id}`)
          .then(() => {
            this.$emit('refreshTable');
            this.request.comments.splice(index, 1)
          })
          .finally(() => {
            this.submitLoading = false;
          })
    },
  },
};
</script>
